"use client";

import * as cmpstub from "@iabtcf/stub";
import dynamic from "next/dynamic";
import { useSearchParams } from "next/navigation";
import React, { createContext, useEffect, useMemo, useState } from "react";
import type { Nullable } from "../../@types/generic";
import {
    getUserSessionDataFromNativeApp,
    sendEventToNativeApp,
} from "../../bridge/helpers";
import { getWindow } from "../../helpers/getWindow";
import {
    useInitialConfig,
    useIsWebview,
    usePageType,
} from "../ApplicationState/hooks";
import { sendError } from "../ErrorBoundary/helpers";
import config from "./config";
import { useContentPass, useUCConsentMode } from "./hooks";
import type { Consent } from "./types";

const CMP = dynamic(() => import("./cmp").then((mod) => mod.CMP), {
    ssr: false,
});

export const ConsentContext = createContext<Consent>({
    consent: false,
    isPay: false,
    openCMP: () => undefined,
});

type Props = {
    children: React.ReactNode;
};

export const ConsentProvider: React.FC<Props> = ({ children }) => {
    const isWebview = useIsWebview();
    const initialConfig = useInitialConfig();
    const window = getWindow();
    const pageType = usePageType();
    const [consent, setConsent] = useState(false);
    const [isPay, setIsPay] = useState(false);
    const [isForced, setIsForced] = useState(false);
    const [skipCMP, setSkipCMP] = useState<Nullable<boolean>>(
        isWebview === true || pageType?.isPlainPage ? true : null
    );
    const { init, auth, remember, clearStorage } = useContentPass();
    const searchParams = useSearchParams();
    useUCConsentMode(consent);

    if (window) {
        // initialize stub api with window.__tcfapi placeholder, to be early available for TCF consumers.
        // Will be replaced by correct __tcfapi later on load time.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        cmpstub();
    }

    const contextValue = useMemo(() => {
        const openCMP = isWebview
            ? (): void => undefined
            : (): void => setIsForced(true);
        return { consent, isPay, openCMP };
    }, [consent, isPay, isWebview]);

    useEffect(() => {
        if (window && isWebview && initialConfig) {
            setIsPay(initialConfig.isPay ?? false);

            if (initialConfig.ucSettingsId) {
                void (async (): Promise<void> => {
                    try {
                        const userSessionData =
                            await getUserSessionDataFromNativeApp();
                        const Usercentrics = await import(
                            "@usercentrics/cmp-browser-sdk"
                        ).then((mod) => mod.default);
                        const UC = new Usercentrics(
                            initialConfig.ucSettingsId as string,
                            { userSessionData }
                        );
                        await UC.init();
                        const consentFromUC = UC.areAllConsentsAccepted();
                        setConsent(consentFromUC);
                        sendEventToNativeApp({
                            type: "consentStatus",
                            consent: consentFromUC ? "full" : "none",
                        });
                    } catch (e) {
                        sendError(e);
                        setConsent(false);
                        sendEventToNativeApp({
                            type: "consentStatus",
                            consent: "none",
                        });
                    }
                })();
            } else {
                sendEventToNativeApp({
                    type: "consentStatus",
                    consent: "none",
                });
            }
        }
    }, [isWebview, initialConfig, window]);

    useEffect(() => {
        if (window && isWebview === false) {
            let ucTcfStorage: { tcString: Nullable<string> } = {
                tcString: null,
            };

            try {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                ucTcfStorage = JSON.parse(
                    window.localStorage.getItem("uc_tcf") ??
                        '{ "tcString": null }'
                );
            } catch (e) {
                // eslint-disable-next-line no-console
                console.warn("error for tcf api localStorage", e);
            }

            if (searchParams.get("cmp") === "accepted") {
                // skip cmp if ?cmp=accepted is present
                setSkipCMP(true);
                setConsent(true);
            } else if (ucTcfStorage.tcString && !isPay) {
                void import("@iabtcf/cmpapi").then((mod) => {
                    const cmpApi = new mod.CmpApi(
                        config.cmp.id,
                        config.cmp.version,
                        false
                    );

                    cmpApi.update(ucTcfStorage.tcString);
                    setSkipCMP(true);
                    setConsent(true);
                });
            } else {
                setSkipCMP(false);
            }

            void init().then(() => {
                void auth().then((isLoggedIn) => {
                    if (isLoggedIn) {
                        remember();
                        setIsPay(true);
                        setSkipCMP(true);
                    }
                });
            });
        }
    }, [
        window,
        auth,
        clearStorage,
        init,
        isPay,
        remember,
        searchParams,
        isWebview,
    ]);

    try {
        return (
            <ConsentContext value={contextValue}>
                {children}
                {skipCMP === false || isForced ? (
                    <CMP
                        setConsent={setConsent}
                        setIsPay={setIsPay}
                        forcedShow={isForced}
                        setIsForced={setIsForced}
                    />
                ) : null}
            </ConsentContext>
        );
    } catch (e) {
        sendError(e);
        return null;
    }
};
