const config = {
    cmp: {
        id: 329,
        version: 2,
    },
    uc: {
        settingsId: "-tQcJ4HR2",
        settingsIdLessVendors: "TrmLkK_BX",
        settingsIdPoland: "2ibcfN0VQXWLFS",
    },
    copa: {
        id: "d14d2899",
        url: "https://cpass.wetter.com",
        storage: "_cpuser",
        wcomStorage: "wcom_cmp_pu",
        isAllowed: "isAllowed",
        price_de: "3,99€",
        price_at: "3,99€",
        price_ch: "CHF 3.99",
        price_pl: "4,99zł",
    },
};

export default config;
