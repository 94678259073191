import type { TrackingProviderPayload } from "@p7s1/oasis-types";
import type {
    CLSAttribution,
    FCPAttribution,
    FIDAttribution,
    INPAttribution,
    LCPAttribution,
    MetricWithAttribution,
    TTFBAttribution,
} from "web-vitals/attribution";
import type {
    CountryType,
    Nullable,
    ObjectToUnionType,
} from "../@types/generic";
import type { TrackingEventNameWithIntervalEvents } from "../components/OasisPlayer/types";

export type MobileWebType =
    | "web-app-react"
    | "web-app-react/ios"
    | "web-app-react/android"
    | "web-app-react/unknown";

// require "undefined" to be set, to unset Overlay state when coming from another page
export type OverlayType =
    | undefined
    | "vhs"
    | "feedback"
    | "ski_livecam"
    | "ski_filter"
    | "inactive";

// require "undefined" to be set, to unset Overlay state when coming from another page
type OverlayStatus = undefined | false | "open" | "close";

export type DataLayerItemPageTrack = {
    type: "pageTrack";
    event: "pagetrack";
    pageType?:
        | "Home"
        | "Vorhersage"
        | "Skigebiete"
        | "HD Live Webcams"
        | "Pollenlexikon"
        | "Compliance"
        | "governance";
    pageTypeDetail?: string;
    mobileWebType?: MobileWebType;
    pageCountry?: CountryType;
    pageRegion?: string;
    pageCity?: string;
    pageId?: string;
    pageName?: string;
    tcontent?: string;
    tcdist?: string;
    overlay: OverlayStatus;
    overlayType: OverlayType;
    ab_5232?: string;
    ab_5497_a?: string;
    ab_5497?: string;
};

export type DataLayerItem = ObjectToUnionType<
    "type",
    {
        pageTrack: DataLayerItemPageTrack;
        overlay: {
            event: "overlay";
            overlay: OverlayStatus;
            overlayType?: OverlayType;
        };
        event: {
            event:
                | "ai~2MinutenLayer"
                | "ai~5MinutenLayer"
                | "ae~2MinutenLayer"
                | "ae~5MinutenLayer"
                | "clicktrack"
                | "ae~push"
                | "savedata"
                | "measurement"
                | "consent_status";
            eventCategory:
                | "function"
                | "teaser"
                | "Save Data Check"
                | "Web Vitals"
                | "gtag";
            eventAction: string | boolean;
            eventLabel: string;
            eventValue?: string | number;
            nonInteraction?: boolean;
        };
        webVitals: {
            event: "measurement";
            eventCategory: "Web Vitals";
            eventAction: MetricWithAttribution["name"];
            eventLabel: string;
            eventValue: number;
            attribution:
                | CLSAttribution
                | FCPAttribution
                | FIDAttribution
                | INPAttribution
                | LCPAttribution
                | TTFBAttribution;
        };
        adBlock: {
            adBlock: "Yes";
        };
        push: {
            type: "push";
            push: "unsubscribe" | "subscribe";
        };
        currentWeatherFeedback: {
            event: "feedback_current_weather";
            condition: Nullable<number>;
            temperature: Nullable<number>;
            suggestedCondition: number;
            suggestedTemperature: string;
            location: Nullable<string>;
            lat: number;
            long: number;
            run: Nullable<string>;
        };
        player: {
            event: TrackingEventNameWithIntervalEvents;
        } & TrackingProviderPayload;
        scrollEnd: {
            event: "slider_scroll_end";
            slider: string;
        };
        adReload: {
            event: "adReload";
            count: number;
            page: string;
        };
    }
>;
export const isPageTrackDataLayerItem = (
    obj: DataLayerItem | undefined
): obj is DataLayerItemPageTrack =>
    (obj?.hasOwnProperty("pageType") && obj.hasOwnProperty("pageTypeDetail")) ||
    false;
