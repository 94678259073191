import type { Nullable } from "../@types/generic";
import { getWindow } from "./getWindow";
import {
    isPageTrackDataLayerItem,
    type DataLayerItem,
    type DataLayerItemPageTrack,
} from "./types";

export const pushDataLayer = (obj: DataLayerItem): void => {
    const window = getWindow();
    if (window) {
        window.dataLayer = window.dataLayer ?? [];
        const type = obj.type;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        delete obj.type;

        // If there is no entry with pageType and pageTypeDetail, set this as first entry in the array.
        // Otherwise, just push to the end.
        if (
            type === "pageTrack" &&
            !isPageTrackDataLayerItem(window.dataLayer[0])
        ) {
            window.dataLayer.unshift(obj);
        } else {
            window.dataLayer.push(obj);
        }
    }
};

export const getPageTrackItem = (): Nullable<DataLayerItemPageTrack> => {
    const window = getWindow();
    if (isPageTrackDataLayerItem(window?.dataLayer?.[0])) {
        return window.dataLayer[0];
    }
    return null;
};
