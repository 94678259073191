import type Usercentrics from "@usercentrics/cmp-browser-sdk";
import type { BaseCategory, TCFData } from "@usercentrics/cmp-browser-sdk";
import { useTranslations } from "next-intl";
import { useCallback, useContext, useEffect, useRef } from "react";
import type { Nullable } from "../../@types/generic";
import { pushDataLayer } from "../../helpers/dataLayer";
import { getWindow } from "../../helpers/getWindow";
import {
    useCountry,
    useIsPersistingForbidden,
    useIsWebview,
} from "../ApplicationState/hooks";
import config from "./config";
import { ConsentContext } from "./provider";
import type { AccordionData, Consent } from "./types";

export const useConsent = (): boolean => {
    const context = useContext<Consent>(ConsentContext);
    return context.consent;
};

export const useIsPay = (): boolean => {
    const context = useContext<Consent>(ConsentContext);
    return context.isPay;
};

export const useOpenCMP = (): ((layer?: number) => void) => {
    const context = useContext<Consent>(ConsentContext);
    return context.openCMP;
};

type UseContentPass = {
    init: () => Promise<boolean>;
    auth: () => Promise<boolean>;
    login: (UC: Usercentrics) => void;
    signup: (UC: Usercentrics) => void;
    remember: () => void;
    clearStorage: () => void;
    logout: (UC: Usercentrics) => void;
};

export const useContentPass = (): UseContentPass => {
    const isWebview = useIsWebview();
    const window = getWindow();
    const isPersistingForbidden = useIsPersistingForbidden();
    const country = useCountry();

    const loadScript = useCallback((): Promise<boolean> => {
        if (isWebview !== false) {
            return new Promise<boolean>((resolve) => {
                resolve(true);
            });
        } else {
            /* eslint-disable */
            return new Promise<boolean>((resolve, reject) => {
                // @ts-ignore
                !(function (C, o, n, t, P, a, s) {
                    // @ts-ignore
                    C["CPObject"] = n;
                    // @ts-ignore
                    C[n] ||
                        // @ts-ignore
                        (C[n] = function () {
                            // @ts-ignore
                            (C[n].q = C[n].q || []).push(arguments);
                        });
                    // @ts-ignore
                    C[n].l = +new Date();
                    // @ts-ignore
                    a = o.createElement(t);
                    // @ts-ignore
                    s = o.getElementsByTagName(t)[0];
                    // @ts-ignore
                    a.src = P;
                    // @ts-ignore
                    a.onload = function () {
                        resolve(true);
                    };
                    // @ts-ignore
                    a.onerror = function () {
                        reject(true);
                    };
                    // @ts-ignore
                    a.id = "copa-nowjs";
                    // @ts-ignore
                    !document.querySelector("#copa-nowjs")
                        ? // @ts-ignore
                          s.parentNode.insertBefore(a, s)
                        : resolve(true);
                })(
                    window,
                    document,
                    "cp",
                    "script",
                    `${config.copa.url}/now.js`
                );
            });
        }
        /* eslint-enable */
    }, [isWebview, window]);

    const create = useCallback((): void => {
        if (isWebview === false && typeof window?.cp === "function") {
            if (country === "pl") {
                window.cp("create", config.copa.id, {
                    baseUrl: config.copa.url,
                    setPropertyConfig: (
                        propertyConfig: Record<string, string>,
                        cb: (opts: null, config: Record<string, string>) => void
                    ): void => {
                        const newPropertyConfig = {
                            ...propertyConfig,
                            offeredPlanCurrency: "PLN",
                        };
                        cb(null, newPropertyConfig);
                    },
                });
            } else if (country === "ch") {
                window.cp("create", config.copa.id, {
                    baseUrl: config.copa.url,
                    setPropertyConfig: (
                        propertyConfig: Record<string, string>,
                        cb: (opts: null, config: Record<string, string>) => void
                    ): void => {
                        const newPropertyConfig = {
                            ...propertyConfig,
                            offeredPlanCurrency: "CHF",
                        };
                        cb(null, newPropertyConfig);
                    },
                });
            } else {
                window.cp("create", config.copa.id, {
                    baseUrl: config.copa.url,
                });
            }
        }
    }, [isWebview, window, country]);

    const init = useCallback((): Promise<boolean> => {
        if (isWebview !== false) {
            return new Promise<boolean>((resolve) => {
                resolve(true);
            });
        }

        return new Promise<boolean>((resolve) => {
            if (window) {
                void loadScript().then(() => {
                    create();
                    resolve(true);
                });
            }
        });
    }, [isWebview, window, create, loadScript]);

    const auth = useCallback((): Promise<boolean> => {
        if (isWebview !== false) {
            return new Promise<boolean>((resolve) => {
                resolve(true);
            });
        }

        return new Promise<boolean>((resolve, reject) => {
            if (typeof window?.cp === "function") {
                window.cp("authenticate", (error, user) => {
                    if (
                        !error &&
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
                        user.isLoggedIn() &&
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
                        user.hasValidSubscription()
                    ) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                });
            } else {
                reject(
                    new Error(
                        "ContentPass not initialized / available. Did you call `useInitContentPass`?"
                    )
                );
            }
        });
    }, [isWebview, window]);

    const login = useCallback(
        (UC: Usercentrics): void => {
            if (isWebview === false && typeof window?.cp === "function") {
                void UC.clearStorage().then(() => {
                    window.cp("login");
                });
            }
        },
        [window, isWebview]
    );

    const signup = useCallback(
        (UC: Usercentrics): void => {
            if (
                isWebview === false &&
                typeof window?.cp === "function" &&
                !isPersistingForbidden
            ) {
                void UC.clearStorage().then(() => {
                    window.cp("signup");
                });
            }
        },
        [isWebview, isPersistingForbidden, window]
    );

    const remember = useCallback((): void => {
        if (isWebview === false) {
            const storage = window?.localStorage.getItem(
                config.copa.wcomStorage
            );

            if (!storage && !isPersistingForbidden) {
                window?.localStorage.setItem(
                    config.copa.wcomStorage,
                    window.btoa(Date.now().toString())
                );
            }
        }
    }, [isWebview, isPersistingForbidden, window]);

    const clearStorage = useCallback((): void => {
        if (
            isWebview === false &&
            !isPersistingForbidden &&
            window &&
            "localStorage" in window
        ) {
            window.localStorage.removeItem(config.copa.storage);
            window.localStorage.removeItem(config.copa.wcomStorage);
            window.localStorage.removeItem(config.copa.isAllowed);
        }
    }, [isWebview, isPersistingForbidden, window]);

    const logout = useCallback(
        (UC: Usercentrics): void => {
            if (isWebview === false && typeof window?.cp === "function") {
                void UC.clearStorage().then(() => {
                    window.cp("logout");
                });
            }
        },
        [isWebview, window]
    );

    return { init, auth, login, signup, remember, clearStorage, logout };
};

/**
 * UC Consent Mode
 * @ref WR-4547
 * @param consent
 */
export const useUCConsentMode = (consent: boolean): void => {
    const window = getWindow();
    const isSet = useRef({
        beforeConsent: false,
        afterConsent: false,
    });

    useEffect(() => {
        if (window) {
            window.dataLayer = window.dataLayer || [];
            window.gtag =
                window.gtag ||
                function gtag(): void {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    // eslint-disable-next-line prefer-rest-params
                    window.dataLayer?.push(arguments);
                };

            if (!isSet.current.beforeConsent) {
                window.gtag("consent", "default", {
                    ad_storage: "denied",
                    ad_user_data: "denied",
                    ad_personalization: "denied",
                    analytics_storage: "denied",
                    wait_for_update: 2000, // milliseconds to wait for update
                });

                // Enable ads data redaction by default [optional]
                window.gtag("set", "ads_data_redaction", true);

                isSet.current.beforeConsent = true;
            } else if (consent && !isSet.current.afterConsent) {
                window.gtag("consent", "update", {
                    ad_storage: "granted",
                    ad_user_data: "granted",
                    ad_personalization: "granted",
                    analytics_storage: "granted",
                });
                pushDataLayer({
                    type: "event",
                    event: "consent_status",
                    eventAction: "consent",
                    eventLabel: "update",
                    eventCategory: "gtag",
                });

                isSet.current.afterConsent = true;
            }
        }
    }, [window, consent]);
};

export const usePrepareSettingsData = (
    UC: Usercentrics | undefined,
    layer: "first" | "second"
): Array<AccordionData> => {
    const accordionData: Array<AccordionData> = [];
    const tcfData: Nullable<TCFData> = UC?.getTCFData() ?? null;
    const categories: Array<BaseCategory> = UC?.getCategoriesBaseInfo() ?? [];
    const t = useTranslations("component.consent.accordion");

    if (!tcfData) {
        return [];
    }

    tcfData.purposes.forEach((item) => {
        if (!item.stackId) {
            accordionData.push({
                title: item.name,
                text: `${item.description}\n${item.descriptionLegal ?? ""}`,
            });
            if (layer === "first") {
                // only add one item for first layer
                return;
            }
        }
    });

    tcfData.stacks.forEach((stack) => {
        if (stack.purposeIds.length > 0) {
            const subItems: Array<AccordionData> = [];
            tcfData.purposes.forEach((item) => {
                if (item.stackId === stack.id) {
                    subItems.push({
                        title: item.name,
                        text: `${item.description}\n${item.descriptionLegal ?? ""}`,
                    });
                    if (layer === "first") {
                        // only add one item for first layer
                        return;
                    }
                }
            });

            accordionData.push({
                title: stack.name,
                text: stack.description,
                subItems: subItems,
            });
        }
    });

    tcfData.specialFeatures.forEach((feature, index) => {
        accordionData.push({
            title: feature.name,
            text: `${feature.description}\n${feature.descriptionLegal ?? ""}`,
        });

        if (layer === "second" && index === 1) {
            // only add two items for first layer
            return;
        }
    });

    // only add if not in first layer
    if (layer === "second") {
        categories.forEach((category) => {
            if (category.slug !== "customCategory03") {
                accordionData.push({
                    title: `${category.label} *`,
                    text: category.description,
                });
            }
        });

        const specialPurposeSubItems = tcfData.specialPurposes.map(
            (purpose) => {
                return {
                    title: purpose.name,
                    text: `${purpose.description}\n${purpose.descriptionLegal ?? ""}`,
                };
            }
        );
        accordionData.push({
            title: t("specialPurposes.title"),
            text: t("specialPurposes.description"),
            subItems: specialPurposeSubItems,
        });

        const featuresSubItems = tcfData.features.map((feature) => {
            return {
                title: feature.name,
                text: `${feature.description}\n${feature.descriptionLegal ?? ""}`,
            };
        });
        accordionData.push({
            title: t("features.title"),
            text: t("features.description"),
            subItems: featuresSubItems,
        });
    }

    return accordionData;
};
